import { lazy } from 'react'
const JitOrderCutoff = lazy(() => import('../containers/opsManager/pages/Products/JitProduct/jitOrderCutoff'));
const JitOrderCutoffDetails = lazy(() => import('../containers/opsManager/pages/Products/JitProduct/jitOrderCutoffDetails'));
const XWarehouseTransfers = lazy(() => import('../containers/opsManager/pages/Warehouses/XWarehouseTransfers/xWarehouseTransfers'));
const InterWarehouseEligibleProducts = lazy(() => import('../containers/opsManager/pages/Warehouses/XWarehouseTransfers/interWarehouseEligibleProducts.js'));
const TransferredProducts = lazy(() => import('../containers/opsManager/pages/Warehouses/XWarehouseTransfers/transferredProducts'));
const TransferProductCutoff = lazy(() => import('../containers/opsManager/pages/Warehouses/XWarehouseTransfers/transferProductCutoffs.js'));
const TransferProductCutoffDetails = lazy(() => import('../containers/opsManager/pages/Warehouses/XWarehouseTransfers/transferProductCutoffDetails.js'));
const NavigationBar = lazy(() => import('../layouts/Header/navigationBar'));
const Footer = lazy(() => import('../layouts/Footer/footer'));
const LoginPage = lazy(() => import('../containers/opsManager/pages/Login/loginPage'));
const RedirectLoginPage = lazy(() => import('../containers/opsManager/pages/Login/redirectLogin.js'));
const SetPassword = lazy(() => import('../containers/opsManager/pages/Passwords/SetPassword/setPassword'));
const ChangePassword = lazy(() => import('../containers/opsManager/pages/Passwords/ChangePassword/changePassword'));
const ForgotPassword = lazy(() => import('../containers/opsManager/pages/Passwords/ForgotPassword/forgotPassword'));
const Profile = lazy(() => import('../containers/opsManager/pages/Profile/profile'));
const HomePage = lazy(() => import('../containers/opsManager/pages/Home/homePage'));
const SoldProductsPage = lazy(() => import('../containers/opsManager/pages/Products/SoldProduct/soldProductPage'));
const InventoryProductsPage = lazy(() => import('../containers/opsManager/pages/Products/InventoryProduct/inventoryProductPage'));
const JITProductsPage = lazy(() => import('../containers/opsManager/pages/Products/JitProduct/jitProductPage'));
const ProductPackagingPage = lazy(() => import('../containers/opsManager/pages/Products/ProductPackaging/productPackagingPage'));
const CategoriesPage = lazy(() => import('../containers/opsManager/pages/Categories/categoriesPage'));
const EnumerationApiTypes = lazy(() => import('../containers/opsManager/pages/EnumerationTypes/enumerationTypes.js'));
const WarehousePage = lazy(() => import('../containers/opsManager/pages/Warehouses/Warehouse/warehousePage'));
const StorageEditorPage = lazy(() => import('../containers/opsManager/pages/Warehouses/WarehouseStorageEditor/warehouseStorageEditorPage'));
const WarehouseRoute = lazy(() => import('../containers/opsManager/pages/Warehouses/WarehouseRoute/warehouseRoutePage'));
const WarehouseOrderTemplate = lazy(() => import('../containers/opsManager/pages/Warehouses/WarehouseOrderTemplate/warehouseOrderTemplate'));
const WarehouseOrderTemplateLocations = lazy(() => import('../containers/opsManager/pages/Warehouses/WarehouseOrderTemplate/warehouseOrderTemplateLocations'));
const WarehouseOrderTemplateProducts = lazy(() => import('../containers/opsManager/pages/Warehouses/WarehouseOrderTemplate/warehouseOrderTemplateProducts'));
const WarehousePricingTemplate = lazy(() => import('../containers/opsManager/pages/Warehouses/WarehousePricingTemplate/warehousePricingTemplate'));
const WarehousePricingTemplateLocations = lazy(() => import('../containers/opsManager/pages/Warehouses/WarehousePricingTemplate/warehousePricingTemplateLocations'));
const WarehousePricingTemplateProducts = lazy(() => import('../containers/opsManager/pages/Warehouses/WarehousePricingTemplate/warehousePricingTemplateProducts'));
const VendorsPage = lazy(() => import('../containers/opsManager/pages/Vendors/Vendor/vendorPage'));
const VendorOrders = lazy(() => import('../containers/opsManager/pages/Vendors/Vendor/vendorOrders.js'));
const VendorStandingOrders = lazy(() => import('../containers/opsManager/pages/Vendors/Vendor/vendorStandingOrders.js'));
const VendorToWarehousePage = lazy(() => import('../containers/opsManager/pages/Vendors/VendorWarehouse/vendorToWarehousePage'));
const VendorToProductsPage = lazy(() => import('../containers/opsManager/pages/Vendors/VendorProduct/vendorToProductPage'));
const UserRolesPage = lazy(() => import('../containers/opsManager/pages/UserRole/userRolePage'));
const CustomerLocation = lazy(() => import('../containers/opsManager/pages/Locations/customerLocations'));
const ProductConfiguration = lazy(() => import('../containers/opsManager/pages/Locations/productConfiguration.js'));
const Notifications = lazy(() => import('../containers/opsManager/pages/Notifications/notifications.js'));
const DeliveryManifest = lazy(() => import('../containers/opsManager/pages/DeliveryManifest/deliveryManifest'));
const SupplementalOrders = lazy(() => import('../containers/opsManager/pages/DeliveryManifest/supplementalOrders.js'));
const DeliveryRoutes = lazy(() => import('../containers/opsManager/pages/DeliveryManifest/deliveryRoutes'));
const PickingOrders = lazy(() => import('../containers/opsManager/pages/DeliveryManifest/pickingOrders'));
const AllDeliveryOrders = lazy(() => import('../containers/opsManager/pages/DeliveryManifest/allDeliveryOrders'));
const UpdateDeliveryOrder = lazy(() => import('../containers/opsManager/pages/DeliveryManifest/updateDeliveryOrder.js'));
const DeliveryDetails = lazy(() => import('../containers/opsManager/pages/DeliveryManifest/deliveryDetails'));
// const Picking = lazy(() => import('../containers/opsManager/pages/Picking/picking'));
const MyPicks = lazy(() => import('../containers/opsManager/pages/Picking/myPicks'));
const PickOrderTable = lazy(() => import('../containers/opsManager/pages/Picking/myPickOrderTable'));
const Bays = lazy(() => import('../containers/opsManager/pages/PickingSequence/bays'));
const Slots = lazy(() => import('../containers/opsManager/pages/PickingSequence/slots'));
// const Deliveries = lazy(() => import('../containers/opsManager/pages/Deliveries/deliveries'));
const MyDeliveries = lazy(() => import('../containers/opsManager/pages/Deliveries/myDeliveries'));
const PlacedOrders = lazy(() => import('../containers/opsManager/pages/Reports/placedOrders.js'));
const PlacedOrdersDetails = lazy(() => import('../containers/opsManager/pages/Reports/placedOrdersDetails.js'));
const ForeCastOrders = lazy(() => import('../containers/opsManager/pages/Reports/forecastOrders.js'));
const ForecastOrdersDetails = lazy(() => import('../containers/opsManager/pages/Reports/forecastOrdersDetails.js'));
const CutOffOrders = lazy(() => import('../containers/opsManager/pages/Reports/cutOffOrders.js'));
const CutOffOrdersDetails = lazy(() => import('../containers/opsManager/pages/Reports/cutOffOrdersDetails.js'));
const PrintOrderInvoices = lazy(() => import('../containers/opsManager/pages/Deliveries/printOrderInvoices.js'));
const OrderEditorPage = lazy(() => import('../containers/catapultCustomer/pages/OrderEditor/orderEditorPage.js'));
const CartOrder = lazy(() => import('../containers/catapultCustomer/pages/CartOrder/cartOrder'));
const Orders = lazy(() => import('../containers/catapultCustomer/pages/Orders/orders'));
const NotFoundPage = lazy(() => import('../containers/opsManager/pages/NotFound/notFoundPage'));
// const WorkInProgress = lazy(() => import('../containers/opsManager/pages/NotFound/workInProgress'));

export { NavigationBar, Footer, NotFoundPage };

export const UnAuthorizedRoutes = [
    {
        path: "/login",
        Component: LoginPage
    },
    {
        path: "/redirect-login",
        Component: RedirectLoginPage
    },
    {
        path: "/set-password",
        Component: SetPassword
    },
    {
        path: "/forgot-password",
        Component: ForgotPassword
    }
];

export const AuthorizedRoutes = [
    {
        path: "/users-and-roles",
        Component: UserRolesPage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/warehouse",
        Component: WarehousePage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/warehouse-editor",
        Component: StorageEditorPage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/warehouse-route",
        Component: WarehouseRoute,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/jit-order-cutoff",
        Component: JitOrderCutoff,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/jit-product-cutoff-details",
        Component: JitOrderCutoffDetails,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/warehouse-order-template",
        Component: WarehouseOrderTemplate,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/warehouse-order-template-locations",
        Component: WarehouseOrderTemplateLocations,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/warehouse-order-template-products",
        Component: WarehouseOrderTemplateProducts,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/warehouse-pricing-template",
        Component: WarehousePricingTemplate,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "CustomerSupport", "Sales"]
    },
    {
        path: "/warehouse-pricing-template-locations",
        Component: WarehousePricingTemplateLocations,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "CustomerSupport", "Sales"]
    },
    {
        path: "/warehouse-pricing-template-products",
        Component: WarehousePricingTemplateProducts,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "CustomerSupport", "Sales"]
    },
    {
        path: "/picking-sequence-bays",
        Component: Bays,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor"]
    },
    {
        path: "/picking-sequence-slots",
        Component: Slots,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor"]
    },
    {
        path: "/categories",
        Component: CategoriesPage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "CatalogAdmin"]
    },
    {
        path: "/csv-values",
        Component: EnumerationApiTypes,
        allAccess: true,
        allowedRoles: ["CompanyAdmin", "LocationAdmin", "OperationsUser", "PaymentUser", "BillingUser",
        "PartnerAdmin", "CatalogAdmin", "OperationsAdmin", "OperationsSupervisor", "Picker", "Receiver", "DeliveryDriver", "CustomerSupport", "Sales"]
    },
    {
        path: "/x-warehouse-transfers",
        Component: XWarehouseTransfers,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "CatalogAdmin", "OperationsAdmin", "OperationsSupervisor"]
    },
    {
        path: "/transferred-products",
        Component: TransferredProducts,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "CatalogAdmin", "OperationsAdmin", "OperationsSupervisor", "Picker", "Receiver", "DeliveryDriver", "CustomerSupport", "Sales"]
    },
    {
        path: "/eligible-transfer-products",
        Component: InterWarehouseEligibleProducts,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "CatalogAdmin", "OperationsAdmin", "OperationsSupervisor", "Picker", "Receiver", "DeliveryDriver", "CustomerSupport", "Sales"]
    },
    {
        path: "/transfer-product-cutoff",
        Component: TransferProductCutoff,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "CatalogAdmin", "OperationsAdmin", "OperationsSupervisor", "Picker", "Receiver", "DeliveryDriver", "CustomerSupport", "Sales"]
    },
    {
        path: "/transfer-product-cutoff-details",
        Component: TransferProductCutoffDetails,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "CatalogAdmin", "OperationsAdmin", "OperationsSupervisor", "Picker", "Receiver", "DeliveryDriver", "CustomerSupport", "Sales"]
    },
    {
        path: "/products",
        Component: SoldProductsPage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CatalogAdmin"]
    },
    {
        path: "/inventory-products",
        Component: InventoryProductsPage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CatalogAdmin"]
    },
    {
        path: "/jit-products",
        Component: JITProductsPage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CatalogAdmin"]
    },
    {
        path: "/vendors",
        Component: VendorsPage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CatalogAdmin", "Sales"]
    },
    {
        path: "/vendors-placed-orders",
        Component: VendorOrders,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CatalogAdmin", "Sales"]
    },
    {
        path: "/vendors-standing-orders",
        Component: VendorStandingOrders,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CatalogAdmin", "Sales"]
    },
    {
        path: "/vendors-products",
        Component: VendorToProductsPage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CatalogAdmin", "Sales"]
    },
    {
        path: "/product-packaging",
        Component: ProductPackagingPage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CatalogAdmin", "Sales"]
    },
    {
        path: "/vendors-warehouse",
        Component: VendorToWarehousePage,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CatalogAdmin", "Sales"]
    },
    {
        path: "/customer-locations",
        Component: CustomerLocation,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/product-configuration",
        Component: ProductConfiguration,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/delivery-manifests",
        Component: DeliveryManifest,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/all-delivery-orders",
        Component: AllDeliveryOrders,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/update-delivery-order",
        Component: UpdateDeliveryOrder,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/supplemental-order",
        Component: SupplementalOrders,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/delivery-routes",
        Component: DeliveryRoutes,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/picking-orders",
        Component: PickingOrders,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        path: "/my-picks",
        Component: MyPicks,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "Picker"]
    },
    {
        path: "/my-picks-order",
        Component: PickOrderTable,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "Picker"]
    },
    {
        path: "/my-deliveries",
        Component: MyDeliveries,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "DeliveryDriver"]
    },
    {
        path: "/placed-orders",
        Component: PlacedOrders,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "DeliveryDriver"]
    },
    {
        path: "/placed-orders-details",
        Component: PlacedOrdersDetails,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "DeliveryDriver"]
    },
    {
        path: "/forecast-orders",
        Component: ForeCastOrders,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "DeliveryDriver"]
    },
    {
        path: "/forecast-orders-details",
        Component: ForecastOrdersDetails,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "DeliveryDriver"]
    },
    {
        path: "/cutoff-orders",
        Component: CutOffOrders,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "DeliveryDriver"]
    },
    {
        path: "/cutoff-orders-details",
        Component: CutOffOrdersDetails,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "DeliveryDriver"]
    },
    {
        path: "/print-invoices",
        Component: PrintOrderInvoices,
        allAccess: false,
        allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "DeliveryDriver"]
    },
    // {
    //     path: "/picking",
    //     Component: Picking,
    //     allAccess: false,
    //     allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    // },
    // {
    //     path: "/deliveries",
    //     Component: Deliveries,
    //     allAccess: false,
    //     allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    // },
    // {
    //     path: "/receiving",
    //     Component: WorkInProgress,
    //     allAccess: false,
    //     allowedRoles: ["PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales", "Receiver"]
    // }
];

export const CustomerAuthorizedRoutes = [
    {
        exact: true,
        path: "/",
        Component: HomePage,
        allAccess: true,
        allowedRoles: ["CompanyAdmin", "LocationAdmin", "OperationsUser", "PaymentUser", "BillingUser",
            "PartnerAdmin", "CatalogAdmin", "OperationsAdmin", "OperationsSupervisor", "Picker", "Receiver", "DeliveryDriver", "CustomerSupport", "Sales"]
    },
    {
        exact: false,
        path: "/cart-order",
        Component: CartOrder,
        allAccess: false,
        allowedRoles: ["CompanyAdmin", "LocationAdmin", "OperationsUser",
            "PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        exact: false,
        path: "/orders",
        Component: Orders,
        allAccess: false,
        allowedRoles: ["CompanyAdmin", "LocationAdmin", "OperationsUser",
            "PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        exact: false,
        path: "/delivery-details",
        Component: DeliveryDetails,
        allAccess: false,
        allowedRoles: ["CompanyAdmin", "LocationAdmin", "OperationsUser",
            "PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        exact: false,
        path: "/order-editor",
        Component: OrderEditorPage,
        allAccess: false,
        allowedRoles: ["CompanyAdmin", "LocationAdmin", "OperationsUser",
            "PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
    {
        exact: true,
        path: "/profile",
        Component: Profile,
        allAccess: true,
        allowedRoles: ["CompanyAdmin", "LocationAdmin", "OperationsUser", "PaymentUser", "BillingUser",
            "PartnerAdmin", "CatalogAdmin", "OperationsAdmin", "OperationsSupervisor", "Picker", "Receiver", "DeliveryDriver", "CustomerSupport", "Sales"]
    },
    {
        exact: true,
        path: "/change-password",
        Component: ChangePassword,
        allAccess: true,
        allowedRoles: ["CompanyAdmin", "LocationAdmin", "OperationsUser", "PaymentUser", "BillingUser",
            "PartnerAdmin", "CatalogAdmin", "OperationsAdmin", "OperationsSupervisor", "Picker", "Receiver", "DeliveryDriver", "CustomerSupport", "Sales"]
    },
    {
        exact: true,
        path: "/notifications",
        Component: Notifications,
        allAccess: false,
        allowedRoles: ["CompanyAdmin", "LocationAdmin", "OperationsUser",
        "PartnerAdmin", "OperationsAdmin", "OperationsSupervisor", "CustomerSupport", "Sales"]
    },
];